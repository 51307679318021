@import 'Styles/spacing.module.scss';

.articleWrapper {
  width: 100%;
  box-sizing: border-box;
  white-space: normal;

  &.border {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
  }

  &.verticalWrapper {
    padding: $space-md;
  }

  &.horizontalWrapper {
    padding: $space-sm;
  }

  .clickableArea {
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 90%;
    }

    .imageContainer {
      aspect-ratio: 90/60;
      height: auto;
      width: 100%;
      display: flex;
      align-items: center;
      overflow-y: clip;

      &.verticalImageContainer {
        margin-bottom: $space-xsm;
        position: relative;
      }

      &.horizontalImageContainer {
        width: 100px;
        flex-shrink: 0;
        position: relative;
      }

      @supports not (aspect-ratio: 90/60) {
        padding-top: calc(60 / 90 * 100%);
        height: 0;
        position: relative;
        overflow: hidden;
      }
    }

    .verticalImage {
      width: 100%;
      height: auto;
      border-radius: 4px;
    }

    .horizontalImage {
      width: 90px;
      height: auto;
      border-radius: 4px;
    }

    .horizontalContentWrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .textContent {
        flex-grow: 1;

        .authorSkeleton {
          margin: 2px 0px 2px 0px;
        }
      }
    }

    .title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      min-height: calc(var(--sizeMod) * 3rem);

      &.verticalTitle {
        margin-bottom: $space-xsm;
      }

      &.horizontalTitle {
        margin-bottom: 0;
      }
    }

    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0;

      &.verticalDescription {
        height: 54px;
        -webkit-line-clamp: 3;
      }

      &.horizontalDescription {
        height: $space-xlg;
        -webkit-line-clamp: 2;
      }

      .descriptionWrapper {
        position: relative;
        height: inherit;

        .readMoreWrapper {
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;

          .gradientTransition {
            width: $space-xxlg;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0),
              var(--c) 75%
            );
          }

          .readMore {
            background-color: var(--c);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .readMoreButton {
      margin-top: $space-md;
    }
  }

  .actionComponent {
    display: flex;
    justify-content: center;
    margin-top: $space-md;
  }
}

.reducedDescription {
  display: none;
}
