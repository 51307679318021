.modal {
  padding: 1rem;
  max-width: 320px;
}

.subtitle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.superHeader {
  display: flex;
  align-items: center;
}

.header {
  // display: flex;
  // align-items: center;
  // text-indent: -1rem;
  // margin-left: 1rem;
}

.example {
  // background-color: #eee;
  // padding: 0.5rem;
  // margin: 0.25rem;
}

.qrCodeRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrCode {
  margin: 0.5rem auto;
  display: block;
}
