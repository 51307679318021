.saytItemText {
  line-height: 1.5rem;
}

.saytMisoAutoSuggest {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;

  & > mark {
    font-weight: bold;
    color: black;
    background-color: transparent;
  }
}
