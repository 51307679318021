@media print {
  .recBlock {
    display: none;
  }
}

.recommendation_cards {
  display: inline-flex;
  width: min-content;
  column-gap: 1.5rem;
  justify-content: space-evenly;
  align-items: flex-start;
}

.recSection {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .card {
    scroll-margin-top: calc(48px + 3.5rem);
  }
}

.noRecs {
  padding: 1rem;
}
