@import 'Styles/transition.module.scss';

.mask {
  @include transition(opacity $slow);
  z-index: 95;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
