.topbarLink {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: none;
}

.topbarIcon {
  margin-right: 5px;
}
