.bar {
  position: fixed;
  top: 0;
  width: 100%;
  font-size: 0;
  height: 0.25rem;
  background-color: rgb(27, 117, 208, 0.5);
  z-index: 1000;
}

.barValue {
  width: 100%;
  height: 100%;
  background-color: rgb(27, 117, 208);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }

  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
