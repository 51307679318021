.popoverTrigger {
  display: flex;
}

.arrow {
  width: 15px;
  height: 15px;
}

.popover {
  box-shadow:
    0 0px 3px rgba(0, 0, 0, 0.35),
    0 5px 10px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  background-color: white;
  padding: 20px 10px;
  text-align: center;
  display: flex;
  z-index: 1;

  &[data-popper-placement^='top'] > .popoverArrow {
    bottom: -30px;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
}
