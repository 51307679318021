@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';

.saytSearch {
  border-radius: $space-pill-border-radius;
  min-height: 54px;
  font-size: 1.1rem;

  input {
    font-size: 1.1rem;
  }
}

.saytModalContainer {
  padding: 10px;
}

.transparentHeader:global(.p) {
  color: white;
}

.saytLink {
  text-decoration: none;
  cursor: pointer;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.saytWrapper {
  display: flex;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.suggestedSearchWrapper {
  flex-direction: column;
  flex: 0 1 300px;
}

.saytSecondColumnWrapper {
  flex-direction: column;
  flex: 2;
}

.saytSearchSuggestions {
  margin: 10px;
  border-radius: 5px;
}

.saytSearchBackground {
  background: white !important;
}

.saytItems {
  display: flex;
  flex-wrap: wrap;
  & img {
    max-width: 100%;
  }

  > .listItem {
    text-decoration: none;
    min-width: 275px;
    flex: 0;
    padding: 10px;
    align-items: center;
    display: flex;

    &:hover {
      text-decoration: underline;
    }
  }
}

.saytItemImage {
  min-width: 85px;
  height: auto;
  position: relative;
  min-height: 85px;
  margin-right: $space-xsm;
}

.suggestedPlaceHolderText {
  margin: 1rem 0 1rem 1rem;
}
.placeHolderText {
  margin: 1rem 0 1rem 0;
}

.articleText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.closeIconWrapper {
  margin: $space-m;
  margin-left: auto;
  :hover {
    cursor: pointer;
  }
}

.modalClose {
  cursor: pointer;
  font-size: 1.25em;
  padding: 7px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
}

.saytHr {
  margin-top: 5px;
}

.searchBarTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.saytContainer {
  width: 1280px;
}

.hideDesktop {
  display: none;
}

.suggestedSearchPartTypes {
  margin-left: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

@include mobileMediaQuery {
  .saytContainer {
    width: 640px;
  }
  .hideMobile {
    display: none;
  }

  .hideDesktop {
    display: flex;
  }

  .isMobile {
    display: flex;
    align-items: center;
  }

  .isDesktop {
    display: none;
  }
}

// This is a custom query to get products showing at a certain tablet view
@media (max-width: 700px) {
  .saytSecondColumnWrapper {
    display: none;
  }

  .suggestedSearchWrapper {
    flex: 0 0 100%;
  }

  .saytContainer {
    max-width: 90%;
  }
}

@include tabletMediaQuery {
  .saytProducts {
    > .listItem {
      min-width: 250 px;
    }
  }

  .saytContainer {
    max-width: 920px;
  }

  .isMobile {
    display: none;
  }
}

@include desktopMediaQuery {
  .isMobile {
    display: none;
  }
}

.ymmPillText {
  padding-right: 5px;
}
.ymmPillX {
  top: 1px;
  position: relative;
  cursor: pointer;
}
.ymmPill {
  background-image: none;
  border-radius: 16rem;
}
