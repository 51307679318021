.link {
  padding: 1rem 1rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: flex;
  border-radius: 4px;
}

.linkWithImage {
  flex-direction: column;
  align-items: center;
}

.linkInnerText {
  display: block;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.linkInnerTextSpacing {
  margin-right: 2rem;
}

.link:hover,
.link:active {
  box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.imageSkeleton {
  margin-bottom: 0;
}

.link:focus:not(:focus-visible):after {
  content: '';
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: calc(50% - 1rem);
  border: 0.25rem solid var(--spinnerColor, black);
  border-bottom: 0.25rem solid transparent;
  border-radius: 100%;
  animation:
    rotating 1s linear infinite,
    softin 0.25s linear 1 forwards;
}

.link {
  position: relative;
}

.link:focus:not(:focus-visible)::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--hoverColor, white);
  opacity: 0.5;
  top: 0;
  left: 0;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@keyframes softin {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
