@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';
@import 'Styles/fonts.module.scss';

.mainDrawerNav {
  @include fontStandard();

  p {
    margin: 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .drawerNavBanner {
    background-color: #575757;

    .listItem {
      .listItemText {
        line-height: 1.25rem;
      }
    }

    .listItem:nth-child(odd) {
      border-right: 1px #888 solid;
    }

    .listItem:nth-child(even) {
      border-left: 1px #888 solid;
    }

    .listItemIcon {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 1rem;
      padding-right: 0.5rem;
    }
  }

  .drawerNavHeader {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .drawerNavFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px 0px 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    gap: 0.5rem;
  }

  .drawerCallButton {
    width: 100%;
  }
}

.primaryNavItems {
  width: 100%;

  .listItem {
    padding: 12px 20px 12px 20px;
    align-items: center;

    //animation: primaryNavFadeIn 3000ms ease;

    // @for $i from 1 through 7 {
    //     &:nth-child(#{$i}) {
    //       opacity:0;
    //       animation-delay: 600ms * $i;
    //     }
    //   }

    .listItemTextContainer {
      flex: 1 1 auto;
      justify-content: flex-start;
    }

    .listItemIcon {
      justify-content: flex-end;
    }
  }
}

.drawerNavContent {
  height: 100%;
  position: relative;
  width: 100%;
}

.subNavigation {
  margin: 10px;
  border-radius: 5px;

  .subNavigationItemSearch {
    padding-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .featuredArticleTitle {
    margin-left: $space-md;
    padding-top: $space-xsm;
  }
}

.recommendedContent {
  padding: 10px;
}

.secondaryNav {
  .secondaryFeaturedSection {
    margin: 10px 10px 10px 10px;
  }
}

.subNavigationList {
  display: flex;
  margin: 0px 10px 0px 10px;
  padding-top: 5px;
  padding-bottom: 5px;

  > .listItem {
    padding: 10px 5px 10px 5px;

    .subItem {
      margin: -0.3rem;
      padding-left: $space-md;
    }

    &:hover {
      border-radius: 0.5rem;
    }

    > .listItemText {
      flex: 1 1 auto;
    }

    > .listItemIcon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.tertiaryNav {
  .tertiaryItemSearch {
    margin: 10px 10px 10px 10px;
  }
}

.returnNav {
  display: flex;
  cursor: pointer;
  height: 75px;

  .returnIcon {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .returnText {
    display: flex;
    flex: 0 1 170px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    align-items: center;
    justify-content: flex-end;
    text-align: right;
  }
}

.primaryReturnItem {
  display: none;
}

@include mobileMediaQuery {
  .primaryReturnItem {
    display: flex;
  }

  .mainDrawerNav {
    // This is one greater than the actual height of the drawer as there is a border on the control bar
    // that makes this look awkward if not set this way
    margin-bottom: 57px;

    .drawerNavHeader {
      display: none;
    }

    .drawerNavBanner {
      display: none;
    }
  }
}

@include tabletMediaQuery {
  .drawerCallButton {
    display: none;
  }
}

@include desktopMediaQuery {
  .drawerCallButton {
    display: none;
  }
}

@include mobileLimitedMediaQuery {
  .mainDrawerNav {
    .drawerNavHeader {
      display: none;
    }

    .drawerNavBanner {
      display: none;
    }
  }
}

.appInstallFrame {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.seeAllToolboxArticlesContainer {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

// @keyframes primaryNavFadeIn {

//     0%   { opacity: 0; }
//     100% { opacity: 1; }
// }
