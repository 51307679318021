@import 'Styles/spacing.module.scss';
@import 'Styles/transition.module.scss';
@import 'Styles/breakpoints.module.scss';

.carouselControl {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  position: relative;

  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  padding: 8px 16px;
  cursor: pointer;

  background: var(--backgroundColor);

  @include transition(
    transform 250ms ease-out,
    background-color $fast,
    border-color $fast,
    opacity $fast
  );

  &.carouselControlLeft:active {
    transform: translateX(-10px);
  }

  &.carouselControlRight:active {
    transform: translateX(10px);
  }
}

.controlLeftSvg,
.controlRightSvg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@include mobileMediaQuery {
  .carouselControl {
    margin-right: 0;
    margin-left: 0;
  }

  .carouselControlHidden {
    display: none;
  }
}

@include desktopMediaQuery {
  .carouselControl {
    display: inline-block;

    user-select: none;
    -moz-user-select: none;

    &.carouselControlLeft {
      margin-right: $space-xs;
      left: 0;
    }

    &.carouselControlRight {
      margin-left: $space-xs;
      right: 0;
    }
  }
}

.carouselControl > * {
  pointer-events: none; // Do not let e.target be an Svg or use (children of the button that are clickable)
}

@media (hover: hover) {
  .carouselControl:hover {
    background: var(--hoverBackgroundColor);
    border-color: var(--hoverBorderColor);
  }

  .carouselControl {
    @include transition(
      transform 250ms ease-out,
      background-color $fast,
      border-color $fast,
      opacity $fast
    );

    &.carouselControlLeft:hover {
      transform: translateX(-10px);
    }

    &.carouselControlRight:hover {
      transform: translateX(10px);
    }
  }
}
