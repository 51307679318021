.quantity {
  border-radius: 4px;
  height: 36px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  width: 120px;
}

.leftButton {
  left: 0;
  right: 80px;
}

.rightButton {
  left: 80px;
  right: 0;
}

.svg_wrapper {
  display: flex;
  height: 100%;
}

.svg {
  margin: auto;
}

.button {
  position: absolute;
  top: 0;
  bottom: 0;

  background-color: transparent;
  text-align: center;
  user-select: none;
  padding-top: 0;

  -webkit-transition: background-color 200ms ease-in-out; /* Safari prior 6.1 */
  transition: background-color 200ms ease-in-out;
}

.input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  right: 40px;
  width: 40px;
  border: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  outline: none;
  height: 100%;
  background: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
}
