.radioInputItem {
  display: flex;
  flex-direction: column;

  margin-left: 0.75rem;
  width: 100%;
}

.radioInputBody {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: inherit;
}

.padded {
  padding: 0.75rem;
}

.radioLabelText {
  width: 100%;
}

.radioLabelSubText {
  width: fit-content;
  padding-bottom: 0.75rem;
}

.radioItem {
  margin: 0 0.75rem 0 0;
}

.radioItem:hover {
  cursor: pointer;
}

.radioLabelText:hover {
  cursor: pointer;
}

.radioLabelSubText:hover {
  cursor: pointer;
}

.divider {
  width: 100%;
}
