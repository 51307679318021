@import 'Styles/transition.module.scss';
@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';

.drawerPanel {
  left: 0;
  flex: 1 1 auto;
  right: 0;
  z-index: 1;
  min-height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.drawerZIndex {
  --drawer-mask-z-index: 99999999;
}

.drawerIconWrapper {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  cursor: pointer;
}

.drawerIcon {
  fill: hsla(0, 0%, 100%, 0.8);
  stroke: hsla(0, 0%, 100%, 0.8);
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  &:hover {
    //background-color: black;
    fill: white;
    stroke: white;
  }
}

.bottom_drawer_wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  position: fixed;
  max-height: 100vh;
  z-index: 99;
  -webkit-overflow-scrolling: touch;
}

.bottom_drawer_panel {
  bottom: 0;
  left: 0;
  right: 0;

  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
