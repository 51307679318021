@import 'Styles/spacing.module.scss';
@import 'Styles/colors.module.scss';
@import 'Styles/transition.module.scss';

.textbox {
  background: $light;
  margin: $space-xs 0;
  border-radius: 5px;

  > input {
    outline: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    background: $light;
    padding: $space-xs;
    padding-left: $space-s;

    line-height: 2rem;
    font-size: 1rem;
  }

  input:disabled {
    background-color: var(--disabled);
    cursor: not-allowed;
    opacity: 0.8;
  }

  input:focus {
    box-shadow: 0 0 2px #333;
  }
}

.prefixedTextbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-sm;

  border-style: solid;
  border-width: 0.125rem;
  box-sizing: border-box;
  padding: $space-xs;

  > input {
    border: none;
    outline: none;
    padding: 0;
    line-height: 1rem;
  }
}

.textbox {
  &.fill {
    width: 100%;

    > input {
      width: 100%;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.hideSpinner::-webkit-outer-spin-button,
.hideSpinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hideSpinner[type='number'] {
  -moz-appearance: textfield;
}
