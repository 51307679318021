@import 'Styles/breakpoints.module.scss';

//when inverse button hovers work properly remove this

.garageHeaderFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 0.5rem;
  margin: 0rem;
}

.garageHeading {
  text-align: left;
}

.garageDivider {
  margin-top: 10px !important;
}
