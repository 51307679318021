.featuredArticleContainer {
  padding: 12px 16px 16px 16px;
}

.featuredArticleButton {
  margin-top: 14px;
  width: 100%;
}

.featuredArticleTitle {
  margin-bottom: 9px;
}

.featuredArticleSkeletonButton {
  width: 100%;
}
