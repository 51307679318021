@import 'Styles/transition.module.scss';
@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';

.controlBar {
  width: 100%;
  z-index: 99999999;
  bottom: 0;
}

.fixControlBar {
  position: fixed;
}

.controlBarPanel {
  display: flex;
  padding: 0px;
  align-items: center;
  height: 56px;
}

@mixin wideStlying() {
  .controlBar {
    display: none;
  }
}

@include desktopMediaQuery {
  @include wideStlying();
}

@include tabletMediaQuery {
  @include wideStlying();
}

@include mobileLimitedMediaQuery {
  .controlBarPanel {
    height: 56px;
  }
}

@media print {
  .controlBar {
    display: none;
  }
}

@include pwaMediaQuery {
  @supports (-webkit-touch-callout: none) {
    .controlBarPanel {
      padding-bottom: 0.75rem;
    }
  }
}
