.listItem {
  list-style-type: none;
  display: flex;
  padding: 5px 5px 5px 5px;
  flex: 1 1 auto;
  background: none;
  // This sucks to override for ie. Swap for unset whenever we can on most of these props anyway
  text-align: inherit;
  letter-spacing: normal;
  background-color: var(--base);
  cursor: var(--cursor);
  border: var(--border);
  border-color: var(--borderColor);

  &:hover {
    background-color: var(--hoverColor);
  }
}

nav > .listItem {
  cursor: pointer;
}
