$outer-loader-size: 100px;
$inner-spinner-size: 91px;

@media (min-width: 0px) {
  .ds9LoadingIcon {
    content: '';
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: block !important;
    background-repeat: no-repeat;
    margin: auto;
    z-index: 999999998;
    background-image: url(//content.speedwaymotors.com/OtherImages/wheel_s.svg);
    background-color: rgba(0, 0, 0, 0.5);
    animation: fade 0.3s 1 linear;
    background-position: center;
    background-size: 100px;

    .ds9LoadingIconSpinner {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: block !important;
      background-repeat: no-repeat;
      margin: auto;
      z-index: 999999999;
      background-image: url(//content.speedwaymotors.com/OtherImages/wheel_spin.svg);
      animation:
        spin 1.3s infinite linear,
        fade 0.3s 1 linear;
      background-position: center;
      background-size: 91px;
    }
  }
}

// Rotate
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
}

@keyframes spin {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
