@import 'Styles/transition.module.scss';

.text_link {
  cursor: pointer;

  &:hover {
    .chevron {
      transform: translateX(10px);
    }
    .chevronRight {
      transform: translateX(10px);
    }
    .svg {
      transform: none;
    }
  }
}

.chevron {
  cursor: pointer;
  transition: transform 250ms ease-out;
}

.chevronRight {
  cursor: pointer;
  transition: transform 250ms ease-out;
  float: right;
  margin-right: 1rem;
}

.svg {
  cursor: pointer;
  right: 1rem;
}

.svgLink {
  align-items: center;
  justify-content: space-between;
}
