.gutters {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 450px;
}

.image {
  margin: 1rem;
  height: auto;
  width: auto;
  padding: 70px;
}
