.button {
  border: 0.1rem solid #fff !important;
  border-radius: 8px;
}

.textContainer {
  text-align: left;
}

.iconContainer {
  margin-right: 16px;
}
