@import 'Styles/breakpoints.module.scss';
@import 'Styles/colors.module.scss';
@import 'Styles/spacing.module.scss';

.bannerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.bannerContainer.bannerBottom {
  position: fixed;
  bottom: 3.5rem;
}

@include pwaMediaQuery {
  @supports (-webkit-touch-callout: none) {
    .bannerContainer.bannerBottom {
      bottom: 4.25rem;
    }
  }
}

.banner {
  display: flex;
  justify-content: center;
  padding: 5px;
  align-items: center;
}

@include mobileMediaQuery {
  .desktop {
    display: none;
  }

  .bannerMobileTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 75vw;
  }
}

@include tabletMediaQuery {
  .mobile {
    display: none;
  }

  .bannerContainer.bannerBottom {
    bottom: 0;
  }
}

@include desktopMediaQuery {
  .mobile {
    display: none;
  }

  .bannerContainer.bannerBottom {
    bottom: 0;
  }
}

.bannerContentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;

  &.promotion {
    cursor: pointer;
  }
}

.bannerDescTitleWrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
  letter-spacing: 1px;
}

.bannerText {
  margin: 0px;
  padding: 0px;
}

p.bannerTitle {
  margin-right: $space-sm;
}

.promoCodeWrapper {
  padding: 4px;
  border: white 1px dashed;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  &.modalPromoCodeWrapper {
    border-color: black;
    width: fit-content;
  }
}

.svg {
  flex-shrink: 0;
}

@keyframes collapse {
  from {
    height: 34px;
  }

  to {
    height: 0;
  }
}

.skeleton {
  animation-name: collapse;
  animation-delay: 5s;
  height: 34px;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-iteration-count: 1;
  margin-bottom: 0;
}

.promoCodeText {
  letter-spacing: 1px;
  padding: 0px;
  margin: 0px;
}

.promoCode {
  margin-right: 5px;
}

.promotionModal {
  padding: 40px;
  border-radius: 0.5rem;

  :not(:first-child) {
    margin-bottom: 10px;
  }

  .modalPromoCodeSection {
    display: flex;
    align-items: center;

    .copyPromoButton {
      margin-left: $space-sm;
    }
  }
}

.modalText {
  margin: 0px;
  padding: 0px;
}

.modalTitle {
  line-height: normal;
  padding-bottom: 1rem;
}
