@import 'Styles/spacing.module.scss';
@import 'Styles/transition.module.scss';

.svg {
  stroke-width: 0;
}

.svgLink {
  @include transition(fill $fast);

  &:hover {
    cursor: pointer;
  }
}

.svgAnchor {
  display: inline-block;
}

.show_apple {
  display: none;
}

.hide_apple {
  display: block;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .show_apple {
    display: block;
  }

  .hide_apple {
    display: none;
  }
}
