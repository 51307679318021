// Needs to handle fill from popover
button.navbarItem {
  height: 100%;
}

.pwaOnly {
  display: none;
}

.navbarItem,
.navbarItemLink {
  align-items: center;
  justify-content: center;

  .navbarItemText {
    margin-left: 10px;

    > p {
      line-height: normal;
      text-align: left;
    }
  }

  .icon {
    margin-right: 5px;
  }

  .textWrapper {
    margin-left: 10px;
  }
}

@media (display-mode: standalone) {
  .pwaOnly {
    display: block;
  }
}
