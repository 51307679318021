@media (max-width: 380px) {
  .card_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .image_container {
    order: 1;
    flex-basis: 40%;
  }
  .list_card_right {
    order: 3;
    flex-basis: 100%;
  }
  .list_card_control {
    order: 2;
    flex-basis: 40%;
    .list_card_svg {
      margin-left: 0px;
    }
  }
}

.list_image_svg {
  height: 50px;
  width: 50px;
  margin-top: 8px;
  aspect-ratio: 1/1;
}

.list_card_right {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: top;
  width: calc(100% - 6rem - 65px);
}

.list_card_control {
  width: 3rem;
  margin-left: 1rem;
  float: right;
  display: flex;
  height: 65px;

  .list_card_svg {
    margin: auto;
    border-radius: 100%;
    border-width: 1px;
    display: flex;
    border-style: solid;
    padding: 7px 7px 7px 7px;
  }
}

.list_card_skeleton {
  display: flex;
  width: 95%;
  height: 69px;
  flex-wrap: wrap;
  justify-content: flex-start;

  .deets {
    flex-basis: 32%;
    order: 2;
    margin-left: 1%;
    padding-left: 1%;
  }
}
