@import 'Styles/spacing.module.scss';
@import 'Styles/transition.module.scss';
@import 'Styles/breakpoints.module.scss';

.carousel {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.carouselExtendGutters {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.carouselWindow {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  -webkit-overflow-scrolling: touch;
}

.carouselInner {
  display: inline-flex;
}

.carouselInnerExtendGutters {
  display: inline-flex;
  margin-left: 1rem;
}

@include desktopMediaQuery {
  .carousel {
    @include clear();
  }

  .carouselWindow {
    float: left;
  }
}

.gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.left_gradient {
  left: -1px;
  right: 90%; // should be flush with left window cutoff
  z-index: 1;
}

.right_gradient {
  left: 90%; // should be flush with right window cutoff
  right: -1px;
}

.hidden {
  visibility: hidden;
}

.hidden_left {
  display: none;
}

.ref_div {
  width: $space-xs;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .carouselExtendGutters {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

@media (min-width: 1024px) {
  .carouselInnerExtendGutters {
    margin-left: 0;
  }
  .carouselExtendGutters {
    margin-left: 0;
    margin-right: 0;
  }
}
