.title {
  margin-bottom: 0.25rem;
}

// Vertical Recs ------------------->

.recWrapperVertical {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: 0.5rem;
  gap: 0.5rem;

  .skeletonItemVertical {
    width: 100%;
    margin: 0 auto;
  }
}

// Grid Recs ------------------->

.recWrapperGrid {
  display: grid;
  justify-content: center;
  column-gap: 0;
  gap: 0.5rem;
}

// Carousel Recs ------------------->

.skeletonItemCarousel {
  min-width: 12rem;
  display: inline-flex;
  flex-direction: column;
  margin: 0 0.5rem;
}

// Card Recs ------------------->

.grid_rec_wrapper {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: 1rem;
  justify-content: space-evenly;
}

.double_landing_page_rec_wrapper {
  display: grid;
  grid-template-columns: 1;
  grid-gap: 1rem;
}

.single_rec_wrapper {
  display: flex;
  justify-content: center;
}

// Misc Styles ------------------->

.divider {
  margin-bottom: 1rem;
}

.noDivider {
  @extend .divider;
  height: 1px;
}

.landingPageSkeleton {
  min-width: 18rem;
  margin-bottom: 0.5rem;
  justify-self: center;
}

@media (min-width: 768px) {
  .card {
    scroll-margin-top: calc(48px + 3.5rem);
  }
}
