@import 'Styles/breakpoints.module.scss';

button.floatingButton {
  position: fixed;
  top: 30%;
  right: 0;
  rotate: -90deg;
  z-index: 2;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 0.05rem;
  background-color: rgba(255, 199, 56, 0.749);
  transform-origin: bottom right;
  transition: none;
}

@include desktopMediaQuery {
  button.floatingButton {
    z-index: 99;
  }
}
