@import 'Styles/spacing.module.scss';
@import 'Styles/colors.module.scss';
@import 'Styles/transition.module.scss';
@import 'Styles/fonts.module.scss';

.textarea {
  border-radius: 5px;
  border-style: solid;
  border-width: 0.125rem;
  box-sizing: border-box;
  background: $light;
  padding: $space-xs;
  padding-left: $space-s;
  font-size: 0.875rem;
  @include fontStandard();
}

.charsRemaining {
  text-align: right;
}
