@import 'Styles/breakpoints.module.scss';

.drawerContainer {
  --transition-speed: 0.3s;
}

.drawer {
  background: #fff;
  width: 95%;
  height: 100%;
  overflow: auto;
  position: fixed;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: transform var(--transition-speed) ease;
  z-index: 1000;
}

.drawerImages {
  display: flex;
  overflow: auto;
}

.drawerPanel {
  width: 100%;
  min-height: 100%;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
}

.drawerContentContainer {
  padding: 0.5rem;
  z-index: 0;
}

.drawerLeft {
  top: 0;
  left: 0;
  transform: translateX(-105%);
}

.drawerRight {
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.drawerTop {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
  height: 40%;
}

.drawerBottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
  height: 40%;
}

.drawerLeftRightOpen {
  transform: translateX(0);
}

.drawerTopBottomOpen {
  transform: translateY(0);
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition:
    opacity var(--transition-speed) ease,
    visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

.drawerOpenBackdrop {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.footerContainer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 0.5rem;
}

.footer_divider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.drawer.stacked {
  width: 100%;
  transition: width var(--transition-speed) ease;
}

.backdrop.stacked {
  background: rgba(0, 0, 0, 0);
}

@media (min-width: 700px) {
  .drawerTop {
    width: 100%;
  }

  .drawerBottom {
    width: 100%;
  }
}

@media (min-width: 700px) and (max-width: 2133px) {
  .drawer {
    width: 532px;
  }

  .drawer.stacked {
    width: calc(532px + (2rem * 1));
  }
}

@media (min-width: 2133px) {
  .drawer {
    width: 30%;
  }

  .drawer.stacked {
    width: calc(30% + (2rem * 1));
  }
}
