.title {
  margin-bottom: 0.25rem;
}

.rec_wrapper_base {
  display: grid;
  justify-content: space-between;
  width: 100%;

  .rec_base {
    margin: 0;
    padding: 0;
    max-width: min-content;
  }
}

.divider {
  margin-bottom: 1rem;
}

.noDivider {
  @extend .divider;
  height: 1px;
}

// Media queries for grid layout

@media (min-width: 1450px) {
  .rec_wrapper {
    grid-template-columns: repeat(6, min-content);
  }
}

@media (min-width: 1335px) and (max-width: 1449px) {
  .rec_wrapper {
    grid-template-columns: repeat(5, min-content);
  }
}

@media (min-width: 952px) and (max-width: 1334px) {
  .rec_wrapper {
    grid-template-columns: repeat(4, min-content);
  }
}

@media (min-width: 680px) and (max-width: 951px) {
  .rec_wrapper {
    grid-template-columns: repeat(3, min-content);
  }
}

@media (min-width: 432px) and (max-width: 679px) {
  .rec_wrapper {
    grid-template-columns: repeat(2, min-content);
  }
}

@media (max-width: 431px) {
  .rec_wrapper {
    grid-template-columns: repeat(1, min-content);
    justify-content: center;
  }
}

// Media queries for grid layout for landing pages

@media (min-width: 1308px) {
  .landing_page_rec_wrapper {
    grid-template-columns: repeat(4, min-content);
  }

  .horizontal_rec_wrapper {
    grid-template-columns: repeat(3, min-content);
  }
}

@media (min-width: 946px) and (max-width: 1307px) {
  .landing_page_rec_wrapper {
    grid-template-columns: repeat(3, min-content);
  }

  .horizontal_rec_wrapper {
    grid-template-columns: repeat(2, min-content);
  }
}

@media (min-width: 602px) and (max-width: 945px) {
  .landing_page_rec_wrapper {
    grid-template-columns: repeat(2, min-content);
  }
}

@media (max-width: 601px) {
  .landing_page_rec_wrapper {
    grid-template-columns: min-content;
    justify-content: center;
  }
}

// Media queries for grid layout for landing pages

@media (max-width: 945px) {
  .horizontal_rec_wrapper {
    grid-template-columns: min-content;
    justify-content: center;
  }
}
