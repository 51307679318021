.badgeContainer {
  position: relative;
  display: inline-block;
  // border: 1px solid black;
}

.badgeBorder {
  position: absolute;
  padding: 1px;
}

.badge {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

*:hover > * > .badgeBorder {
  background-color: var(--borderHoverColor, red);
}
