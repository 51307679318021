.articleImage {
  width: 100%;
  height: auto;
}
.articlePreview {
  &__singleLine {
    & p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__multiLine {
    & p {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
    }
  }
}

.articlePreviewTitle {
  margin: 14px 0px 14px 0px;

  & p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
