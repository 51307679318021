@import 'Styles/spacing.module.scss';
@import 'Styles/breakpoints.module.scss';

.carouselItem {
  display: inline-block;
  position: relative;
  margin-right: $space-m;

  overflow: hidden;
  .carouselItemInner {
    display: block;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
