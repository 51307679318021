.container {
  margin: 0 auto;
}

.mainTitle {
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: bold;
  margin-bottom: 20px;
}

.gridWrapper {
  overflow: hidden;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.gridItem {
  position: relative;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 1 / 1;
}

.gridItem:hover {
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.imagePlaceholder {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.categoryInfo {
  position: absolute;
  bottom: 0;
  background-color: rgba(38, 38, 38, 0.8);
  z-index: 1;
  width: 100%;
}

.categoryTitle {
  font-size: 16px;
  color: white !important;
  line-height: 1.2;
  padding: 10px;
  text-align: center;
}

@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;
  }

  .gridItem {
    aspect-ratio: 1 / 1;
  }

  .gridItem:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 768px) {
  .gridWrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .grid {
    display: flex;
    grid-template-columns: none;
    gap: 16px;
    padding: 16px;
  }

  .gridItem {
    flex: 0 0 50%;
    max-width: 300px;
    aspect-ratio: 1 / 1;
  }

  .gridItem:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  // Optional: Hide scrollbar for cleaner look
  .gridWrapper::-webkit-scrollbar {
    display: none;
  }
  .gridWrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.title {
  --sizeMod: 2;
  text-align: center;

  @media (max-width: 480px) {
    --sizeMod: 1.5;
  }
}
