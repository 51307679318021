@import 'Styles/breakpoints.module.scss';

.controlBarItemPanel {
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  // aligned by text otherwise, icons would become misaligned if it has a badge on it
  p {
    line-height: 0.75rem;
    margin: 0px 0px 0px 0px;
    text-align: center;
  }
}

@include mobileLimitedMediaQuery {
  .mobileLimitedHide {
    display: none;
  }
}
