.optionButton {
  border-radius: 4px;
  padding: 0.375rem 1.125rem;

  -webkit-transition: background-color 200ms ease-in-out;
  /* Safari prior 6.1 */
  transition: background-color 200ms ease-in-out;

  &:hover {
    cursor: pointer;
  }
}

@media (min-width: 460px) {
  .optionButton {
    padding: 0.5rem 1.5rem;
  }
}

.disabled,
.disabled:hover {
  cursor: not-allowed;
}

.spinner {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
}

.buttonWaiting {
  position: relative;
  & p,
  svg,
  h2 {
    opacity: 0;
  }
  & .spinnerWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
    display: flex;
    width: 100%;
    justify-content: center;
    left: 0;
    right: 0;
  }
  cursor: not-allowed;
}
