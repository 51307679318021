@import 'Styles/spacing.module.scss';
@import 'Styles/transition.module.scss';
@import 'Styles/fonts.module.scss';
@import 'Styles/breakpoints.module.scss';

.searchWrapper {
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 16rem;

  // IE 11 fix for search bar to pick up min height correctly
  &:after {
    content: '';
    min-height: inherit;
    font-size: 0;
  }

  form {
    margin: 0;
    display: flex;
    width: 100%;
  }
}

.searchIcon {
  display: inline-flex;
  width: auto;

  button {
    align-items: center;
    display: flex;
    cursor: pointer;
    border: 0;
    padding-right: $space-s;
    background-color: rgba(0, 0, 0, 0);
  }

  &.fillEnd {
    button {
      padding-left: 15px;
    }
  }

  &.fillStart {
    button {
      padding-right: 15px;
    }
  }
}

.searchFillPadding {
  input {
    padding-left: $space-m !important;
  }
}

.searchWrap {
  display: inline-flex;
  width: 100%;
  padding: $space-xs;
  border-bottom-left-radius: 16rem;
  border-top-left-radius: 16rem;
  border-right: 0 !important;

  button {
    white-space: nowrap;
  }
  & .searchLayer {
    width: 100%;
    position: relative;
    text-align: left;
    min-height: 34px;
    & input {
      color: hsla(0, 0%, 0%, 1);
      position: absolute;
      top: 50%;

      transform: translate(0, -50%);
      -webkit-appearance: none;
      font-size: 1rem;
      background: none;
      border: 0;
      outline: none;

      padding: $space-xs;
      padding-left: $space-s;
      width: 100%;
      @include fontStandard();

      &.dummy {
        position: relative;
        transform: none;
        top: 0;
      }

      &.typeAhead {
        opacity: 0.6;
      }

      /* clears the 'X' from Internet Explorer */
      &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }

      &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
      /* clears the 'X' from Chrome */
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
      &::placeholder {
        text-overflow: ellipsis;
      }
      &[placeholder] {
        text-overflow: ellipsis;
      }
    }
  }
}

.isDesktop {
  display: flex;
  align-items: center;
}

.fillEnd {
  padding-right: 10px;
}

@include desktopMediaQuery {
  .isMobile {
    display: none;
  }
}

@include mobileMediaQuery {
  .isMobile {
    display: flex;
    align-items: center;
  }

  .isDesktop {
    display: none;
  }
}

@include tabletMediaQuery {
  .isMobile {
    display: none;
  }
}
