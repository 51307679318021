.center {
  text-align: center;
  margin-top: 1rem;
}

.variant_drawer_header {
  margin: 1rem;
}

.variant_drawer_atc {
  margin-top: 1rem;
}

.variant_drawer_panel {
  padding: 0.5rem;
  flex-grow: 1;
}

.variant_drawer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.notAvailable {
  margin-bottom: 1rem;
}

.filler {
  margin-bottom: 1rem;
  height: 24px;
}

@media (min-width: 460px) {
  .variant_drawer_panel {
    padding: 1.5rem;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
}
