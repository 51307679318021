@import 'Styles/breakpoints.module.scss';
@import 'Styles/spacing.module.scss';
@import 'Styles/fonts.module.scss';

.listCreationModal {
  border-radius: $space-xs;
  overflow: hidden;
  padding: $space-m;
}

.listCreationModalContent {
  text-align: left;
  padding: $space-m $space-xs $space-l;
  height: 100%;
}

.listCreationModalContentTop {
  text-align: left;
  padding: $space-xs $space-xs;
  height: 100%;
}

.listCreationModalContainer {
  max-width: 390px;
  width: 100%;
}

@media (max-height: 600px) {
  .listCreationModalContainer {
    max-width: 320px;
    width: 100%;
  }
}
